import React from "react";

function PrivacyAndSupport() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="57"
            fill="none"
            viewBox="0 0 57 57"
        >
            <rect width="57" height="57" fill="#1B6658" rx="10"></rect>
            <path
                fill="#fff"
                d="M39.34 43.238V25.146a.762.762 0 00-.763-.762h-2.996v-2.981c0-1.963-.78-3.846-2.17-5.235a7.415 7.415 0 00-10.481 0 7.399 7.399 0 00-2.171 5.235v2.985h-2.996a.764.764 0 00-.763.762v18.088a.762.762 0 00.763.762h20.814a.764.764 0 00.763-.762zM22.285 21.403c0-1.56.62-3.054 1.724-4.157a5.888 5.888 0 018.322 0 5.875 5.875 0 011.724 4.157v2.985h-11.77v-2.985zm15.529 21.072H18.526V25.908h19.288v16.567z"
            ></path>
            <path
                fill="#fff"
                d="M27.357 37.175v2.995h1.627v-2.995a2.861 2.861 0 001.604-1.135 2.582 2.582 0 00.425-1.848 2.668 2.668 0 00-.963-1.66 2.977 2.977 0 00-1.88-.66c-.69 0-1.358.235-1.88.66a2.668 2.668 0 00-.963 1.66c-.098.644.052 1.3.425 1.848s.942.951 1.605 1.136zm.813-3.769a1.3 1.3 0 01.692.198c.205.129.364.312.458.527.095.214.12.45.071.678a1.154 1.154 0 01-.34.6 1.27 1.27 0 01-.638.322 1.316 1.316 0 01-.72-.067 1.232 1.232 0 01-.558-.433 1.126 1.126 0 01-.21-.651c0-.311.131-.61.365-.83.233-.22.55-.344.88-.344z"
            ></path>
        </svg>
    );
}

export default PrivacyAndSupport;
