import React from "react";

function IndustryAccreditation() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="57"
            fill="none"
            viewBox="0 0 57 57"
        >
            <rect width="57" height="57" fill="#1B6658" rx="10"></rect>
            <path
                fill="#fff"
                d="M40.983 39.47l-4.26-7.381a4.1 4.1 0 001.534-4.103 4.101 4.101 0 000-5.47 4.096 4.096 0 00-2.735-4.737 4.102 4.102 0 00-4.737-2.734 4.101 4.101 0 00-5.47 0 4.098 4.098 0 00-4.736 2.735 4.101 4.101 0 00-2.735 4.736 4.1 4.1 0 000 5.47 4.1 4.1 0 001.535 4.103l-4.261 7.382a.876.876 0 00.986 1.285l3.544-.95.95 3.544a.873.873 0 00.848.65.873.873 0 00.758-.438l4.26-7.382a4.066 4.066 0 003.173 0l4.261 7.382a.873.873 0 00.873.43.873.873 0 00.733-.642l.95-3.544 3.544.95a.877.877 0 00.985-1.285zM21.53 43.173a.088.088 0 01-.098.048.09.09 0 01-.081-.071l-1.05-3.92a.388.388 0 00-.477-.277l-3.921 1.05a.09.09 0 01-.103-.034.092.092 0 01-.007-.108l4.25-7.361c.173.087.352.162.536.223a4.101 4.101 0 004.737 2.735c.146.13.3.247.462.354l-4.248 7.361zM26.5 35.34a3.308 3.308 0 01-.794-.586.39.39 0 00-.376-.1 3.32 3.32 0 01-4.061-2.345.39.39 0 00-.276-.276 3.321 3.321 0 01-2.343-4.06.389.389 0 00-.1-.376 3.32 3.32 0 010-4.69.388.388 0 00.1-.376 3.32 3.32 0 012.345-4.06.387.387 0 00.275-.276 3.321 3.321 0 014.06-2.345.393.393 0 00.377-.1 3.32 3.32 0 014.69 0 .394.394 0 00.376.1 3.321 3.321 0 014.061 2.345.387.387 0 00.276.276 3.315 3.315 0 012.344 4.06.388.388 0 00.1.376 3.32 3.32 0 010 4.69.388.388 0 00-.1.376 3.32 3.32 0 01-2.346 4.059.388.388 0 00-.275.275 3.318 3.318 0 01-4.061 2.346.393.393 0 00-.377.1 3.312 3.312 0 01-2.344.97c-.54 0-1.073-.131-1.551-.383zm13.803 4.628a.091.091 0 01-.102.035l-3.923-1.05a.39.39 0 00-.476.275l-1.05 3.92a.098.098 0 01-.18.023l-4.248-7.36c.163-.107.317-.225.462-.354a4.098 4.098 0 004.737-2.735c.184-.061.364-.136.537-.223l4.25 7.361a.092.092 0 01-.007.108z"
            ></path>
            <path
                fill="#fff"
                d="M35.843 25.251a7.792 7.792 0 10-7.793 7.793 7.8 7.8 0 007.793-7.793zm-14.806 0a7.013 7.013 0 117.014 7.014 7.021 7.021 0 01-7.014-7.014z"
            ></path>
            <path
                fill="#fff"
                d="M30.569 29.542a.555.555 0 00.697-.137.548.548 0 00.121-.455l-.481-2.807 2.04-1.988a.563.563 0 00-.313-.959l-2.818-.41-1.26-2.554a.563.563 0 00-1.008 0l-1.26 2.553-2.82.41a.563.563 0 00-.31.96l2.039 1.988-.481 2.807a.555.555 0 00.445.644.572.572 0 00.37-.051l2.52-1.325 2.519 1.324zm-5.013-.893l.442-2.576a.388.388 0 00-.112-.345l-1.87-1.824 2.585-.376a.39.39 0 00.293-.212l1.157-2.344 1.156 2.344a.39.39 0 00.294.213l2.586.376-1.872 1.824a.389.389 0 00-.111.345l.442 2.576-2.313-1.216a.388.388 0 00-.363 0l-2.314 1.215z"
            ></path>
        </svg>
    );
}

export default IndustryAccreditation;
