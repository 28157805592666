import React from "react";

function PersonalizedSolutions() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="57"
            fill="none"
            viewBox="0 0 57 57"
        >
            <rect width="57" height="57" fill="#1B6658" rx="10"></rect>
            <path
                fill="#fff"
                d="M22.715 40.403a8.118 8.118 0 10-8.118-8.118 8.128 8.128 0 008.118 8.118zm0-14.939a6.82 6.82 0 11-6.821 6.821 6.829 6.829 0 016.82-6.82z"
            ></path>
            <path
                fill="#fff"
                d="M11 32.285A11.715 11.715 0 1022.715 20.57 11.728 11.728 0 0011 32.285zm11.715-10.417a10.417 10.417 0 11-10.418 10.417 10.43 10.43 0 0110.418-10.417z"
            ></path>
            <path
                fill="#fff"
                d="M39.936 14H17.32a.648.648 0 00-.649.649v4.634a.648.648 0 00.649.648h23.697v3.544h-7.651a.649.649 0 000 1.297h9.31v3.337h-7.374a.649.649 0 100 1.297h8.758v3.337h-7.79a.649.649 0 000 1.297h3.017v3.337h-4.573a.649.649 0 100 1.297h7.997v3.337H31.014a.648.648 0 100 1.297H43.36a.649.649 0 00.648-.649v-4.634a.648.648 0 00-.648-.648h-2.775V34.04h4.123a.649.649 0 00.65-.648v-4.634a.648.648 0 00-.65-.649h-.734v-3.985a.65.65 0 00-.649-.649h-1.011v-4.192a.648.648 0 00-.649-.649h-1.08v-3.985a.648.648 0 00-.649-.649zm-13.763 4.634h-8.204v-3.337h21.319v3.337H26.172z"
            ></path>
        </svg>
    );
}

export default PersonalizedSolutions;
