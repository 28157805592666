import React from "react";

function ProvenSuccess() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="57"
            fill="none"
            viewBox="0 0 57 57"
        >
            <rect width="57" height="57" fill="#1B6658" rx="10"></rect>
            <path
                fill="#fff"
                d="M21.775 26.456c.981 2.113 2.356 3.9 4.163 5.357.28.225.6.4.937.543V36.5h-1.45a.628.628 0 00-.6.45L24.219 39h-1.607a.62.62 0 00-.587.413l-1.362 3.75a.628.628 0 00.075.575.634.634 0 00.512.262h15a.628.628 0 00.587-.844l-1.362-3.75a.62.62 0 00-.587-.412H33.28l-.606-2.05a.63.63 0 00-.6-.444h-1.45v-4.144c.337-.144.663-.319.938-.544a14.734 14.734 0 004.162-5.356c4.306-.5 6.775-3.869 6.775-9.331a.627.627 0 00-.625-.625h-4.388a34.13 34.13 0 00-.087-1.919.627.627 0 00-.625-.581h-16.05c-.325 0-.6.25-.625.581a52.444 52.444 0 00-.088 1.919h-4.387a.627.627 0 00-.625.625c0 5.462 2.469 8.831 6.775 9.331zM35.356 42.75H22.144l.906-2.5h11.4l.906 2.5zM31.975 39h-6.45l.369-1.25h5.712l.369 1.25zm-2.6-2.5h-1.25v-3.8c.206.025.413.05.625.05.212 0 .419-.025.625-.05v3.8zm6.906-11.4c.769-2.138 1.169-4.581 1.213-7.35h3.743c-.106 2.681-.987 6.506-4.956 7.35zm-14.968-9.85h14.875c.424 7.2-1.35 12.3-5.413 15.587-.519.42-1.256.663-2.025.663-.769 0-1.506-.244-2.025-.663-4.069-3.287-5.838-8.387-5.413-15.587zm-1.307 2.5c.038 2.769.444 5.212 1.213 7.35-3.969-.844-4.85-4.669-4.957-7.35h3.744z"
            ></path>
            <path
                fill="#fff"
                d="M25.806 25.55a.632.632 0 00.225.7.619.619 0 00.738 0l1.981-1.444 1.988 1.444a.618.618 0 00.368.119.62.62 0 00.594-.819l-.756-2.331 1.987-1.444a.62.62 0 00.225-.7.63.63 0 00-.593-.431h-2.457l-.756-2.331a.625.625 0 00-1.188 0l-.756 2.33H24.95a.625.625 0 00-.369 1.132l1.988 1.444-.763 2.331zm2.044-3.656a.625.625 0 00.594-.431l.306-.938.306.938a.625.625 0 00.594.43h.981l-.794.582a.62.62 0 00-.224.7l.306.938-.794-.582a.639.639 0 00-.738 0l-.793.582.306-.938a.632.632 0 00-.225-.7l-.794-.581h.969z"
            ></path>
        </svg>
    );
}

export default ProvenSuccess;
