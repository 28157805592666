import React from "react";

function NegotiatingFinancialRelief() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <path
                fill="#1B6658"
                d="M33.778 16h-4.445V6.222A6.229 6.229 0 0023.111 0H6.222A6.23 6.23 0 000 6.222v11.556A6.229 6.229 0 006.222 24h4.445v13.333A2.669 2.669 0 0013.333 40h20.445A6.229 6.229 0 0040 33.778V22.222A6.229 6.229 0 0033.778 16zM6.222 22.222a4.452 4.452 0 01-4.444-4.444V6.222a4.452 4.452 0 014.444-4.444h16.89a4.452 4.452 0 014.444 4.444v15.111c0 .49-.4.89-.89.89H6.223zm32 11.556a4.452 4.452 0 01-4.444 4.444H13.333a.892.892 0 01-.889-.889V24h14.223a2.669 2.669 0 002.666-2.667v-3.555h4.445a4.452 4.452 0 014.444 4.444v11.556zM33.778 28c0 .489-.4.889-.89.889H18.668a.892.892 0 01-.89-.889c0-.489.4-.889.89-.889h14.222c.489 0 .889.4.889.889zm0 4.444c0 .49-.4.89-.89.89H18.668a.892.892 0 01-.89-.89c0-.488.4-.888.89-.888h14.222c.489 0 .889.4.889.888zM22.222 12c0 .489-.4.889-.889.889H7.111A.892.892 0 016.222 12c0-.489.4-.889.89-.889h14.221c.49 0 .89.4.89.889zm-16-4.444c0-.49.4-.89.89-.89h6.221c.49 0 .89.4.89.89 0 .488-.4.888-.89.888H7.111a.892.892 0 01-.889-.888zm16 8.888c0 .49-.4.89-.889.89H7.111a.892.892 0 01-.889-.89c0-.488.4-.888.89-.888h14.221c.49 0 .89.4.89.888z"
            ></path>
        </svg>
    );
}

export default NegotiatingFinancialRelief;
