import React from "react";

function ComprehensiveFinancialAssessment() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="41"
            height="40"
            fill="none"
            viewBox="0 0 41 40"
        >
            <path
                fill="#1B6658"
                fillRule="evenodd"
                d="M20.044 38.667C9.75 38.667 1.376 30.293 1.376 20c0-10.293 8.375-18.667 18.668-18.667S38.71 9.708 38.71 20c0 10.293-8.373 18.667-18.666 18.667zm0-38.667c-11.029 0-20 8.972-20 20s8.971 20 20 20c11.028 0 20-8.972 20-20s-8.972-20-20-20zm0 35.02c8.282 0 15.019-6.737 15.019-15.02 0-8.281-6.737-15.02-15.02-15.02-8.282 0-15.02 6.739-15.02 15.02 0 8.283 6.738 15.02 15.02 15.02zm0-31.373C11.027 3.647 3.69 10.983 3.69 20c0 9.017 7.337 16.353 16.354 16.353S36.396 29.017 36.396 20c0-9.017-7.335-16.353-16.352-16.353zm8.333 11.983a1.26 1.26 0 00-1.78-1.78L17.6 22.846a.669.669 0 01-.993-.056l-3.028-3.81a1.25 1.25 0 00-1.763-.202 1.246 1.246 0 00-.466.84c-.038.333.056.66.264.924l4.294 5.403a1.266 1.266 0 001.062.573c.334 0 .65-.13.887-.368l10.52-10.52zm-.89-3.482c-.693 0-1.344.27-1.834.76l-8.467 8.467-2.563-3.225a2.576 2.576 0 00-2.027-.978c-.582 0-1.154.2-1.61.563a2.57 2.57 0 00-.961 1.732 2.573 2.573 0 00.545 1.904l4.282 5.389a2.6 2.6 0 002.117 1.091c.69 0 1.34-.27 1.83-.759l10.52-10.519a2.595 2.595 0 000-3.666 2.572 2.572 0 00-1.832-.759z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default ComprehensiveFinancialAssessment;
