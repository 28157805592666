import React from "react";

function RegularPayments() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="51"
            height="40"
            fill="none"
            viewBox="0 0 51 40"
        >
            <path
                fill="#1B6658"
                d="M22.705 25.94c-.875 0-1.586-.603-1.586-1.369s.71-1.367 1.586-1.367c.876 0 1.587.601 1.587 1.367 0 .438.383.821.82.821.438 0 .821-.383.821-.82 0-1.423-1.04-2.572-2.407-2.9v-1.423a.841.841 0 00-.82-.82.841.841 0 00-.822.82v1.423c-1.367.328-2.407 1.532-2.407 2.9 0 1.695 1.423 3.009 3.228 3.009.876 0 1.587.601 1.587 1.367s-.711 1.368-1.587 1.368c-.875 0-1.586-.602-1.586-1.368a.841.841 0 00-.821-.82.841.841 0 00-.82.82c0 1.423 1.039 2.572 2.406 2.9v1.422c0 .438.383.821.821.821.438 0 .82-.383.82-.82v-1.423c1.369-.328 2.408-1.532 2.408-2.9 0-1.641-1.477-3.009-3.228-3.009z"
            ></path>
            <path
                fill="#1B6658"
                d="M44.59 40H.82a.841.841 0 01-.82-.82V14.284c0-.437.383-.82.82-.82h43.77c.438 0 .82.383.82.82V39.18c0 .493-.382.82-.82.82zM1.64 38.358H43.77V15.107H1.641v23.252z"
            ></path>
            <path
                fill="#1B6658"
                d="M37.696 36.608h-5.143a.841.841 0 01-.82-.82c0-.438.383-.821.82-.821h4.815l3.118-2.681V21.234l-3.118-2.68h-4.815a.841.841 0 01-.82-.822c0-.437.383-.82.82-.82h5.143c.219 0 .383.054.547.219l3.611 3.118a.879.879 0 01.274.602v11.818c0 .218-.11.437-.274.601l-3.61 3.119a.703.703 0 01-.548.219zM12.857 36.608H7.714a.703.703 0 01-.547-.219l-3.61-3.119a.879.879 0 01-.274-.601V20.85c0-.219.11-.492.273-.602l3.611-3.118c.164-.11.328-.22.547-.22h5.143c.438 0 .82.384.82.821 0 .438-.382.821-.82.821H8.043l-3.119 2.68v11.053l3.119 2.68h4.814c.438 0 .82.383.82.821 0 .438-.382.82-.82.82z"
            ></path>
            <path
                fill="#1B6658"
                d="M22.705 36.608c-5.416 0-9.848-4.432-9.848-9.848 0-5.417 4.432-9.848 9.848-9.848 5.417 0 9.848 4.431 9.848 9.848 0 5.416-4.431 9.848-9.848 9.848zm0-18.055A8.196 8.196 0 0014.5 26.76a8.196 8.196 0 008.206 8.207 8.196 8.196 0 008.207-8.207 8.196 8.196 0 00-8.207-8.207zM23.252 12.918h2.353c.11-.055.219-.055.328-.055.274-.055.547 0 .82.055h2.299c-.438-.93-1.423-1.587-2.517-1.696l-.219-1.368c-.055-.438-.492-.766-.93-.711-.438.054-.766.492-.711.93l.219 1.368c-.712.328-1.259.875-1.642 1.477z"
            ></path>
            <path
                fill="#1B6658"
                d="M50.772 25.337L47.106.717c-.054-.437-.492-.766-.93-.711L2.845 6.516c-.438.055-.766.493-.657.93l.766 5.472h1.642l-.712-4.924 41.69-6.237 3.447 22.978-3.063.438v1.642l4.103-.602c.438 0 .766-.383.711-.876z"
            ></path>
            <path
                fill="#1B6658"
                d="M35.015 6.845l4.76-.711 3.447 2.188.711 4.596h.657c.438 0 .82.219 1.04.492l-.876-5.744c-.055-.22-.164-.438-.383-.547l-4.049-2.572c-.164-.11-.383-.164-.547-.11l-5.088.767c-.438.054-.766.492-.711.93.164.492.547.766 1.04.711zM19.04 12.918c1.149-2.407 3.392-4.213 6.182-4.65 3.666-.548 7.112 1.422 8.644 4.65h1.806c-1.587-4.213-6.018-6.949-10.669-6.237-3.666.547-6.51 3.009-7.769 6.237h1.805zM8.59 12.918l2.133-2.462 4.76-.711c.438-.055.766-.493.712-.93-.055-.438-.493-.766-.93-.712l-5.089.766c-.219.055-.383.11-.492.274l-3.119 3.61c-.054.055-.054.11-.11.165H8.59z"
            ></path>
        </svg>
    );
}

export default RegularPayments;
