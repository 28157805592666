import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import FeatureList from "@components/shared/featureList";
import SharedButton from "../sharedButton";
import ComprehensiveFinancialAssessment from "./icons/comprehensiveFinancialAssessment";
import PersonalizedDebtReliefPlan from "./icons/personalizedDebtReliefPlan";
import NegotiatingFinancialRelief from "./icons/negotiatingFinancialRelief";
import RegularPayments from "./icons/regularPayments";
import AchievingFinancialFreedom from "./icons/achievingFinancialFreedom";
const FinancialResilienceList = [
    {
        description: (
            <>
                <p className="font-bold">Comprehensive Financial Assessment:</p>{" "}
                <span>
                    It begins with a thorough evaluation of your financial
                    landscape, including your debts, income, and financial
                    aspirations.
                </span>
            </>
        ),
        icon: <ComprehensiveFinancialAssessment />,
    },
    {
        description: (
            <>
                <p className="font-bold">Personalized Debt Relief Plan:</p>{" "}
                <span>
                    Based on this assessment, a tailored debt relief plan is
                    crafted, potentially involving debt settlement negotiations,
                    debt consolidation, credit counseling, or, if necessary,
                    bankruptcy guidance.
                </span>
            </>
        ),
        icon: <PersonalizedDebtReliefPlan />,
    },
    {
        description: (
            <>
                <p className="font-bold">Negotiating for Financial Relief:</p>{" "}
                <span>
                    Throughout the process, your chosen debt relief company
                    collaborates with your creditors to secure favorable terms,
                    such as reduced balances or interest rates.
                </span>
            </>
        ),
        icon: <NegotiatingFinancialRelief />,
    },
    {
        description: (
            <>
                <p className="font-bold">Regular Payments:</p>{" "}
                <span>
                    Clients are typically required to make regular contributions
                    to a dedicated account to fund settlements or consolidated
                    payments.
                </span>
            </>
        ),
        icon: <RegularPayments />,
    },
    {
        description: (
            <>
                <p className="font-bold">Achieving Financial Freedom:</p>{" "}
                <span>
                    As the journey unfolds, you'll witness your debt gradually
                    diminishing, eventually leading to a more manageable
                    financial situation and, in some instances, achieving a
                    debt-free status.
                </span>
            </>
        ),
        icon: <AchievingFinancialFreedom />,
    },
];

export default function FinancialResiliencePath(): ReactElement {
    return (
        <section className={styles["mainContainer"]}>
            <div className="lg:pt-[97px] lg:pb-[142px] pt-[52px] pb-[82px]">
                <FeatureList
                    featureList={FinancialResilienceList}
                    mainTitle="The Path to Financial Resilience"
                    mainDescription={
                        "Embarking on the debt relief journey involves a series of pivotal steps aimed at helping you regain your financial footing."
                    }
                    colors={{ mainTitleColor: "#1B6658" }}
                    classes={{
                        mainSectionClasses: "  ",
                        mainTitleClasses:
                            " text-2xl  lg:text-3xl font-bold text-center sm:text-left",
                        mainDescriptionClasses:
                            "mt-[20px] lg:text-xl lg:leading-8 text-center sm:text-left leading-7 ",
                        innerSectionClasses:
                            "flex   flex-wrap   sm:gap-[88px] gap-[60px] mt-[50px] ",
                        oneBoxClasses:
                            "  w-full max-w-[350px] mx-auto sm:mx-0 sm:w-[310px] ",
                        iconClasses: "children:mx-auto sm:children:mx-0 ",
                        descriptionClasses: `font-light text-center sm:text-left `,
                    }}
                />
                <p className="lg:text-xl lg:leading-8 text-center sm:text-left leading-7  lg:mt-[70px] mt-8">
                    Transparent communication with your debt relief company is
                    pivotal to ensuring a successful outcome.
                </p>
                <div className="mt-6  text-center sm:text-left">
                    <SharedButton>
                        Claim Your Free Debt Relief Consultation
                    </SharedButton>
                </div>
            </div>
        </section>
    );
}
