import React from "react";

function AchievingFinancialFreedom() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="29"
            height="40"
            fill="none"
            viewBox="0 0 29 40"
        >
            <path
                fill="#1B6658"
                d="M27.956 18.889H5.733v-8.045c0-4.844 3.956-8.8 8.8-8.8 4.845 0 8.8 3.956 8.8 8.8 0 .578.445 1.023 1.023 1.023.577 0 1.022-.445 1.022-1.023C25.333 4.844 20.444 0 14.488 0 8.534 0 3.645 4.844 3.645 10.844v8.045H1.022C.444 18.889 0 19.333 0 19.91v19.067C0 39.556.444 40 1.022 40h26.89c.577 0 1.021-.444 1.021-1.022V19.91c.045-.533-.444-1.022-.977-1.022zm-1.023 19.067H2.044V20.933h24.89v17.023z"
            ></path>
            <path
                fill="#1B6658"
                d="M13.467 30.133V32c0 .578.444 1.022 1.022 1.022.578 0 1.022-.444 1.022-1.022v-1.867a2.116 2.116 0 001.111-1.866 2.134 2.134 0 00-2.133-2.134c-1.2 0-2.133.934-2.133 2.134 0 .8.444 1.51 1.11 1.866z"
            ></path>
        </svg>
    );
}

export default AchievingFinancialFreedom;
