import React, { ReactElement } from "react";
import Image from "next/image";
import SharedButton from "../sharedButton";
export default function DebtFreePath(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row  lg:gap-40 gap-8 lg:mt-28 mt-14 ">
            <div className="max-w-[550px] mx-auto lg:mx-0 order-2 lg:order-1">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-[#1B6658] ">
                    DebtReliefOasis.com: Your Path to a Debt-Free Life
                </h2>

                <p className="mt-7 font-light lg:text-lg  text-base lg:leading-8 ">
                    Are you grappling with insurmountable debt? Debt relief
                    companies are your expert companions on the journey to
                    financial freedom. Their mission is simple: to provide
                    pragmatic solutions to alleviate your financial burdens.
                    They offer an array of services, including negotiating with
                    creditors to reduce your total debt, streamlining multiple
                    debts into a single manageable payment, offering credit
                    counseling to impart financial wisdom, and guiding you
                    through potential bankruptcy proceedings if necessary.
                </p>
                <p className="mt-4 font-light lg:text-lg  text-base lg:leading-8 ">
                    These companies work closely with you to design personalized
                    debt repayment strategies, negotiate lower interest rates on
                    your behalf, and explore legal options for financial
                    respite. The ultimate objective is to empower you to regain
                    control of your financial destiny and pave the way for a
                    debt-free future.
                </p>
                <div className="mt-6 text-center sm:text-left">
                    <SharedButton>
                        Get Your Free Financial Consultation Now
                    </SharedButton>
                </div>
            </div>
            <div className="w-[271px] h-[263px] md:w-[555px] md:min-w-[555px] md:h-[567px]  relative  mx-auto lg:mx-0 order-1 lg:order-2  ">
                <Image
                    src={"/debtreliefoasis/assets/images/debtFreePathImg.png"}
                    alt="Image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
