import React, { ReactElement } from "react";
import FeatureList from "@components/shared/featureList";
import SharedButton from "../sharedButton";
import ProvenSuccess from "./icons/provenSuccess";
import IndustryAccreditation from "./icons/industryAccreditation";
import TransparentFees from "./icons/transparentFees";
import PersonalizedSolutions from "./icons/personalizedSolutions";
import PrivacyAndSupport from "./icons/privacyAndSupport";
const FinancialResilienceList = [
    {
        title: "Proven Success:",
        description:
            "Seek a company with a track record of helping clients effectively reduce or manage their debts.",

        icon: <ProvenSuccess />,
    },
    {
        title: "Industry Accreditation:",
        description:
            " Accreditation from respected organizations like the American Fair Credit Council (AFCC) or the International Association of Professional Debt Arbitrators (IAPDA) strongly indicates credibility.",

        icon: <IndustryAccreditation />,
    },
    {
        title: "Transparent Fees:",
        description: `
                    Ensure they explain their fees clearly and maintain
                    reasonable and transparent cost structures to prevent
                    unexpected expenses.
           `,
        icon: <TransparentFees />,
    },
    {
        title: "Personalized Solutions:",
        description: `
                    A reputable debt relief company tailors its solutions to
                    your unique financial circumstances and requirements.
           `,
        icon: <PersonalizedSolutions />,
    },
    {
        title: "Privacy and Support:",
        description: `They should prioritize your privacy and data security and
                    offer exceptional customer support throughout your debt
                    relief journey.`,

        icon: <PrivacyAndSupport />,
    },
];

export default function QualitiesCompany(): ReactElement {
    return (
        <div className="lg:mt-[97px] lg:mb-[142px] mt-[52px] mb-[82px]">
            <FeatureList
                featureList={FinancialResilienceList}
                mainTitle="Qualities of a Trustworthy Debt Relief Company"
                mainDescription={
                    "Identifying Your Ideal Debt Relief Partner When searching for a reputable debt relief company, several essential factors should top your checklist:"
                }
                colors={{ mainTitleColor: "#1B6658", titleColor: "#1B6658" }}
                classes={{
                    mainSectionClasses: "  ",
                    mainTitleClasses:
                        " text-2xl  lg:text-3xl font-bold text-center sm:text-left",
                    titleClasses:
                        "text-xl  lg:text-2xl font-bold mt-[30px] text-center sm:text-left",
                    mainDescriptionClasses:
                        "mt-[20px] lg:text-xl lg:leading-8 text-center sm:text-left leading-7 ",
                    innerSectionClasses:
                        "flex   flex-wrap   sm:gap-[88px] gap-[60px] mt-[50px] ",
                    oneBoxClasses: "  w-full  mx-auto sm:mx-0 sm:w-[500px]  ",
                    iconClasses: "children:mx-auto sm:children:mx-0 ",
                    descriptionClasses: `font-light text-center sm:text-left mt-[5px]`,
                }}
            />
            <p className="lg:text-xl lg:leading-8 text-center sm:text-left leading-7  lg:mt-[70px] mt-8">
                Reading client reviews and testimonials can also offer
                invaluable insights into the company's reputation and customer
                satisfaction.
            </p>
            <div className="mt-6  text-center sm:text-left">
                <SharedButton>Begin Your Journey to Debt Freedom</SharedButton>
            </div>
        </div>
    );
}
