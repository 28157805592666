import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import Image from "next/image";
import SharedButton from "../sharedButton";
export default function Benefits(): ReactElement {
    return (
        <section className={styles["mainContainer"]}>
            <div className="flex flex-col lg:flex-row  lg:gap-36 gap-8 lg:pt-28 pt-14 lg:pb-[93px] pb-[50px]">
                <div className="max-w-[550px] mx-auto lg:mx-0 order-2  lg:mt-10">
                    <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-[#1B6658] ">
                        The Benefits of Choosing DebtReliefOasis.com
                    </h2>
                    <p className="mt-7 font-light lg:text-lg  text-base lg:leading-8 ">
                        Financial Liberation
                    </p>
                    <p className="font-light lg:text-lg  text-base lg:leading-8 ">
                        Selecting a debt relief company brings a multitude of
                        advantages for those wrestling with overwhelming debt.
                        These companies specialize in negotiating with creditors
                        to secure reduced debt amounts, lower interest rates, or
                        more manageable repayment terms. With their guidance,
                        you can find solace and freedom from the shackles of
                        unmanageable debt.
                    </p>

                    <div className="mt-6  text-center sm:text-left">
                        <SharedButton>I am Ready to Be Debt-Free</SharedButton>
                    </div>
                </div>
                <div className="w-[271px] h-[263px] md:w-[510px] md:min-w-[510px] md:h-[547px]  relative  mx-auto lg:mx-0 order-1   ">
                    <Image
                        src={"/debtreliefoasis/assets/images/benefitsImg.png"}
                        alt="Image"
                        layout="fill"
                    />
                </div>
            </div>
        </section>
    );
}
