import React from "react";

function PersonalizedDebtReliefPlan() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <path
                fill="#1B6658"
                d="M27.747 22.442c-.673-1.726-2.02-3.032-3.663-3.79A5.461 5.461 0 0019.958 9.6a5.463 5.463 0 00-5.474 5.474c0 1.39.505 2.61 1.348 3.579-1.643.758-2.99 2.105-3.664 3.79a20.074 20.074 0 00-1.136 4.42c-.085.842.126 1.684.673 2.274a2.785 2.785 0 002.148.968h12.126c.842 0 1.6-.337 2.147-.968a2.788 2.788 0 00.716-1.895c0-.126 0-.253-.042-.379-.084-1.558-.463-3.031-1.053-4.42zM20 11.452c1.979 0 3.579 1.6 3.579 3.58 0 1.979-1.6 3.578-3.579 3.578a3.576 3.576 0 01-3.579-3.578c0-1.98 1.6-3.58 3.579-3.58zm6.821 16.422c-.168.21-.463.337-.758.337H13.937c-.295 0-.59-.127-.758-.337a1.055 1.055 0 01-.21-.8 16.087 16.087 0 011.01-3.958c.632-1.6 2.063-2.737 3.705-3.116a5.326 5.326 0 002.316.505c.842 0 1.6-.21 2.316-.505 1.684.379 3.116 1.558 3.705 3.116.505 1.263.842 2.61 1.01 3.958.043.294-.041.59-.21.8z"
            ></path>
            <path
                fill="#1B6658"
                d="M39.074 19.074h-1.642c-.464-8.885-7.622-16-16.506-16.506V.926A.935.935 0 0020 0a.935.935 0 00-.926.926v1.642c-8.885.464-16.042 7.622-16.506 16.506H.926A.935.935 0 000 20c0 .505.421.926.926.926h1.642c.464 8.885 7.622 16 16.506 16.506v1.642c0 .505.42.926.926.926a.935.935 0 00.926-.926v-1.642c8.885-.464 16-7.622 16.506-16.506h1.642A.935.935 0 0040 20a.935.935 0 00-.926-.926zM20.926 35.537v-1.558a.935.935 0 00-.926-.926.935.935 0 00-.926.926v1.558c-7.832-.463-14.106-6.737-14.61-14.61H6.02A.934.934 0 006.947 20a.934.934 0 00-.926-.926H4.463c.463-7.832 6.737-14.106 14.61-14.61v1.515c0 .505.422.926.927.926a.934.934 0 00.926-.926V4.463c7.832.463 14.106 6.737 14.61 14.61h-1.515a.935.935 0 00-.926.927c0 .505.42.926.926.926h1.516c-.463 7.832-6.78 14.148-14.61 14.61z"
            ></path>
        </svg>
    );
}

export default PersonalizedDebtReliefPlan;
